export let hiddenTitlesFO = [
  "Type",
  "id",
  "Asset Class",
  "Location",
  "Group",
  "ISIN",
  "Color",
  "top",
  "bottom",
  // "Sector",
  // "Country",
  // "Issuer",
  "Last Price Update",
  "Last Day Since Realizd",
  "DV01 Dollar Value Impact",
  "Spread Change",
  "DV01 Dollar Value Impact % of Nav",

  "DV01 Dollar Value Impact Limit % of Nav",
  "DV01 Dollar Value Impact Utilization % of Nav",
  "DV01 Dollar Value Impact Test",
  "DV01 Dollar Value Impact Test Color",
  "CR01 Dollar Value Impact",
  "CR01 Dollar Value Impact % of Nav",
  "CR01 Dollar Value Impact Limit % of Nav",
  "CR01 Dollar Value Impact Utilization % of Nav",
  "CR01 Dollar Value Impact Test",
  "CR01 Dollar Value Impact Test Color",

  "Value (BC) % of Nav",
  "Value (BC) Limit % of Nav",

  "Value (BC) Utilization % of Nav",

  "Value (BC) Test",
  "Value (BC) Test Color",
  "Value (BC) % of GMV",
  "Coupon Rate",
  "Rating Score",
  "Long Security Name",
  "Security Description",

  "OAS",
  "OAS W Change",
  "Value (BC) % of Nav",
  "Value (BC) Limit % of Nav",

  "Value (BC) Utilization % of Nav",

  "Value (BC) Test",
  "Value (BC) Test Color",
  "Capital Gain/ Loss since Inception (Live Position)",
  "% of Capital Gain/ Loss since Inception (Live Position)",
  "Accrued Int. Since Inception (BC)",
  "Total Gain/ Loss (USD)",
  "% of Total Gain/ Loss since Inception (Live Position)",
  // "Currency",
  "Pin",
  "LTV",
  "Base LTV",
  // "Borrow Capacity",
  "Rate Sensitivity",
  "Duration Bucket",
  "Day Int. (BC)",
  "MTD Int. (BC)",
  "Average Cost",
  "Region",
  "Market Type",
  "Borrow Capacity",
  "id",
];
export let hiddenTitlesRiskFO = ["Type", "Asset Class", "Color"];

export let hiddenTitlesFOExposure = [
  "Type",
  "Asset Class",
  "id",
  "Security Description",

  "Location",
  // "Group",
  "ISIN",
  "Color",
  "top",
  "bottom",
  "Sector",
  "Country",
  "id",
  // "Sector",
  // "Country",
  // "Issuer",
  "Last Price Update",
  "Last Day Since Realizd",
  "DV01 Dollar Value Impact",
  "Spread Change",
  "DV01 Dollar Value Impact % of Nav",

  "DV01 Dollar Value Impact Limit % of Nav",
  "DV01 Dollar Value Impact Utilization % of Nav",
  "DV01 Dollar Value Impact Test",
  "DV01 Dollar Value Impact Test Color",
  "CR01 Dollar Value Impact",
  "CR01 Dollar Value Impact % of Nav",
  "CR01 Dollar Value Impact Limit % of Nav",
  "CR01 Dollar Value Impact Utilization % of Nav",
  "CR01 Dollar Value Impact Test",
  "CR01 Dollar Value Impact Test Color",

  "Value (BC) % of Nav",
  "Value (BC) Limit % of Nav",

  "Value (BC) Utilization % of Nav",

  "Value (BC) Test",
  "Value (BC) Test Color",
  "Value (BC) % of GMV",
  "Coupon Rate",
  "Rating Score",
  "Long Security Name",
  "OAS",
  "OAS W Change",
  "Value (BC) % of Nav",
  "Value (BC) Limit % of Nav",

  "Value (BC) Utilization % of Nav",

  "Value (BC) Test",
  "Value (BC) Test Color",
  "Capital Gain/ Loss since Inception (Live Position)",
  "% of Capital Gain/ Loss since Inception (Live Position)",
  "Accrued Int. Since Inception (BC)",
  "Total Gain/ Loss (USD)",
  "% of Total Gain/ Loss since Inception (Live Position)",
  // "Currency",
  "Pin",
  "LTV",
  "Base LTV",
  // "Borrow Capacity",
  "Rate Sensitivity",
  "Duration Bucket",
  "Day Int. (BC)",
  "MTD Int. (BC)",
  "Average Cost",
  "Region",
  "Market Type",
  "Borrow Capacity",
];
export let groupSortTitles = ["BB Ticker", "USD Market Value", "DV01", "Day P&L (USD)", "MTD P&L (USD)", "Day Price Move", "MTD Price Move", "Call Date", "Maturity", "3-Day Price Move"];
export let groupSortTitlesAssignBorderKeys: any = {
  // "order" | "groupUSDMarketValue" | "groupDayPl" | "groupMTDPl" | "groupDV01Sum"
  "USD Market Value": "groupUSDMarketValue",
  DV01: "groupDV01Sum",
  "Day P&L (USD)": "groupDayPl",
  "MTD P&L (USD)": "groupMTDPl",
  "Day Price Move": "groupDayPriceMoveSum",

  "MTD Price Move": "groupMTDPriceMoveSum",
  "Call Date": "groupCallDate",
  Maturity: "groupMaturity",
  "BB Ticker": "groupBBTicker",
  "3-Day Price Move":"groupThreeDayPriceMove"
};
export let smallTitlesFO = ["L/S", "Strategy", "Pin", "Category", "Group"];
export let mediumTitlesFO = ["USD Market Value", "Notional Amount", "Call Date", "Maturity", "Call Date"];
export let longTitlesFO = ["BB Ticker", "Issuer", "BBG / S&P / Moody / Fitch Rating", "Borrow Capacity"];
export let leftBorderTitlesFo = ["Bid", "Day P&L (USD)"];
export let rightBorderTitlesFo = ["Spread (Z)", "Day Price Move"];

export let hiddenTitlesRisk = [
  "Mid",
  "Bid",
  "Ask",
  "% of NAV",
  "Day P&L (USD)",
  "Duration",
  "Rating Score",
  "Call Date",
  "BBG / S&P / Moody / Fitch Rating",
  "Day P&L %",
  "MTD P&L (USD)",
  "MTD P&L %",
  "MTD Int. (USD)",
  "MTD Int. (USD) %",
  "URlzd MTD (USD)",
  "URlzd MTD (USD) %",
  "Average Cost",
  "Rlzd MTD (USD) %",
  "Rlzd MTD (USD)",
  "Region",
  "Market Type",
  "Category",
  "Color",
  "Pin",
  "id",
  "Current Spread (T)",
  "Entry Spread (T)",
];
